const polandJson = {
	type: 'FeatureCollection',
	features: [
		{
			type: 'Feature',
			id: 'POL',
			properties: { name: 'Poland' },
			geometry: {
				type: 'Polygon',
				coordinates: [
					[
						[51.106674, 15.016996],
						[51.745188, 14.607098],
						[52.089947, 14.685026],
						[52.62485, 14.4376],
						[52.981263, 14.074521],
						[53.248171, 14.353315],
						[53.757029, 14.119686],
						[54.050706, 14.8029],
						[54.255598, 16.040039],
						[54.513159, 16.363477],
						[54.851536, 17.622832],
						[54.682606, 18.620859],
						[54.438719, 18.696255],
						[54.426084, 19.66064],
						[54.312525, 20.892245],
						[54.327537, 22.731099],
						[54.220567, 23.243987],
						[53.912498, 23.484128],
						[53.470122, 23.527536],
						[53.089731, 23.804935],
						[52.691099, 23.799199],
						[52.486977, 23.199494],
						[52.023647, 23.508002],
						[51.578454, 23.527071],
						[50.705407, 24.029986],
						[50.424881, 23.922757],
						[50.308506, 23.426508],
						[49.476774, 22.51845],
						[49.027395, 22.776419],
						[49.085738, 22.558138],
						[49.470107, 21.607808],
						[49.328772, 20.887955],
						[49.431453, 20.415839],
						[49.217125, 19.825023],
						[49.571574, 19.320713],
						[49.435846, 18.909575],
						[49.49623, 18.853144],
						[49.988629, 18.392914],
						[50.049038, 17.649445],
						[50.362146, 17.554567],
						[50.473974, 16.868769],
						[50.215747, 16.719476],
						[50.422607, 16.176253],
						[50.697733, 16.238627],
						[50.78473, 15.490972],
						[51.10667, 15.016996]
					]
				]
			}
		}
	]
};

export { polandJson };
