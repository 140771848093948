import { Grid } from '@material-ui/core';
import React from 'react';
import './styles/ContactSection.scss';
import 'chart.js';
import fbLogo from '../../../img/fb_logo.png';
import instaLogo from '../../../img/insta_logo.png';
import linkedinLogo from '../../../img/linkedin_logo.png';
import psrpLogo from '../../../img/smallpsrp_logo.png';

const ContactSection = () => {
	return (
		<div className="contact-section">
			<Grid container direction="row" className="grid-container">
				<Grid container item justify="center" alignItems="center">
					<div className="contact-info">
						<a href="http://psrp.org.pl" style={{ color: '#1a4387' }}>
							Parlament Studentów Rzeczypospolitej Polskiej
						</a>
					</div>
					<div className="contact-info">wyzwanie@psrp.org.pl</div>
					<div className="contact-info">
						<a href="https://www.facebook.com/parlamentstudentowrp">
							<img className="logo" alt={fbLogo} src={fbLogo} />
						</a>
						<a href="https://www.linkedin.com/company/the-students'-parliament-of-the-republic-of-poland/">
							<img className="logo" alt={linkedinLogo} src={linkedinLogo} />
						</a>
						<a href="https://www.instagram.com/parlament_studentow_rp/">
							<img className="logo" alt={instaLogo} src={instaLogo} />
						</a>
						<a href="https://psrp.org.pl">
							<img className="logo" alt={psrpLogo} src={psrpLogo} />
						</a>
					</div>
					<div className="contact-info-phone">
						Osoba odpowiedzialna za kontakt z uczestnikami:
						<a href="tel:+48781895212"> 781 895 212</a>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

ContactSection.propTypes = {};

export default ContactSection;
