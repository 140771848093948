import { action, observable } from 'mobx';
import api from '../_app/api';
import { API_ROUTES } from '../_app/routes';

export class ConfigStore {
	@observable nextDeadline = new Date();
	@observable nextTask = '';
	@observable statsMultiplier = -1;

	@action async fetchConfig() {
		try {
			const response = await api.get(API_ROUTES.CONFIG);
			const config = response.data.config[0];
			this.nextDeadline = new Date(config.nextDeadline);
			this.nextTask = config.nextTask;
			this.statsMultiplier = config.statsMultiplier;
		} catch (e) {
			console.log(e);
		}
	}
}

export default new ConfigStore();
