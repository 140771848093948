import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/images/logo.png';
import Button from '@material-ui/core/Button';
import './styles/NavBar.scss';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1
	},
	title: {
		display: 'none',
		[theme.breakpoints.up('sm')]: {
			display: 'block'
		}
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			width: '50%'
		}
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	}
}));

const YellowButton = withStyles(() => ({
	root: {
		fontSize: 17,
		color: '#FFDF2B',
		fontWeight: 900,
		width: '100%',
		lineHeight: 1
	}
}))(Button);

const YellowButtonMobile = withStyles(() => ({
	root: {
		color: '#FFDF2B',
		fontWeight: 900,
		marginRight: 30
	}
}))(Button);

const BlackMenu = withStyles(() => ({
	paper: {
		backgroundColor: 'black'
	}
}))(Menu);

export default function NavBar() {
	const classes = useStyles();
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<BlackMenu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<YellowButtonMobile onClick={handleMenuClose}>
					<Link style={{ color: '#FFDF2B' }} href={'#map-section-title'}>
						TABELA WYNIKÓW
					</Link>
				</YellowButtonMobile>
			</MenuItem>
			<MenuItem>
				<YellowButtonMobile onClick={handleMenuClose}>
					<Link style={{ color: '#FFDF2B' }} href={'#next-task-section-title'}>
						KOLEJNE ZADANIE
					</Link>
				</YellowButtonMobile>
			</MenuItem>
			<MenuItem>
				<YellowButtonMobile onClick={handleMenuClose}>
					<Link style={{ color: '#FFDF2B' }} href={'#previous-task-title'}>
						LEVELE
					</Link>
				</YellowButtonMobile>
			</MenuItem>
			<MenuItem>
				<YellowButtonMobile onClick={handleMenuClose}>
					<Link style={{ color: '#FFDF2B' }} href={'#contact-section-title'}>
						KONTAKT
					</Link>
				</YellowButtonMobile>
			</MenuItem>
			<MenuItem>
				<YellowButtonMobile onClick={handleMenuClose}>
					<Link
						style={{ color: '#FFDF2B' }}
						href={
							'https://psrp.org.pl/wp-content/uploads/2021/02/Regulamin-Podejmij-wyzwanie-z-PSRP.pdf'
						}
						target="_blank"
						rel="noopener"
					>
						REGULAMIN
					</Link>
				</YellowButtonMobile>
			</MenuItem>
		</BlackMenu>
	);

	return (
		<div className={classes.grow}>
			<AppBar
				style={{ background: 'rgba(0,0,0,0.6)', height: 100 }}
				position="fixed"
			>
				<Toolbar style={{ paddingTop: 30 }}>
					<a href="/">
						<img alt={logo} className="navbar-logo" src={logo} />
					</a>
					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						<a href="#map-section-title">
							<YellowButton>TABELA WYNIKÓW</YellowButton>
						</a>
						<a href="#next-task-section-title">
							<YellowButton>KOLEJNE ZADANIE</YellowButton>
						</a>
						<a href="#previous-task-title">
							<YellowButton>LEVELE</YellowButton>
						</a>
						<a href="#contact-section-title">
							<YellowButton>KONTAKT</YellowButton>
						</a>
						<a
							href="https://psrp.org.pl/wp-content/uploads/2021/02/Regulamin-Podejmij-wyzwanie-z-PSRP.pdf"
							target="_blank"
							rel="noreferrer"
						>
							<YellowButton>REGULAMIN</YellowButton>
						</a>
					</div>
					<div className={classes.sectionMobile}>
						<IconButton
							aria-label="show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={handleMobileMenuOpen}
							color="inherit"
						>
							<MenuIcon />
						</IconButton>
					</div>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
		</div>
	);
}
