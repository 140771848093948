import React from 'react';
import ReactDOM from 'react-dom';
import * as stores from './stores';
import { Provider } from 'mobx-react';

import './assets/scss/material-kit-react.scss';
import '@fontsource/roboto/900.css';

// pages for this product
import NavBar from './components/NavBar';
import MainPageContainer from './views/MainPage';
import backgroundImage from './assets/images/background-blured.png';

ReactDOM.render(
	<Provider {...stores}>
		<div>
			<div
				style={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundPosition: 'bottom center',
					padding: 0,
					backgroundColor: 'black'
				}}
			>
				<NavBar />
				<MainPageContainer />
			</div>
		</div>
	</Provider>,
	document.getElementById('root')
);
