import { Grid } from '@material-ui/core';
import React from 'react';
import './styles/MapSection.scss';
import MapView from '../../../components/Map/MapView';
import { ColumnChart } from 'react-chartkick';
import 'chart.js';
import { inject, observer } from 'mobx-react';

const MapSection = ({ statsStore, configStore }) => {
	return (
		<div className="map-section">
			<Grid container spacing={6} direction="row">
				<Grid
					container
					item
					justify="center"
					alignItems="center"
					md={6}
					xs={12}
				>
					<MapView
						statsData={statsStore.stats}
						statsMultiplier={configStore.statsMultiplier}
					/>
				</Grid>
				<Grid
					container
					item
					justify="center"
					alignItems="center"
					md={6}
					xs={12}
				>
					<ColumnChart
						data={statsStore.universitiesStats.charts}
						dataset={{
							backgroundColor: statsStore.universitiesStats.colors,
							borderColor: 'black'
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

MapSection.propTypes = {};

export default inject('statsStore', 'configStore')(observer(MapSection));
