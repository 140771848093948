import { action, computed, observable } from 'mobx';
import { getMonthName } from 'utils/formatDateHelper';
import api from '../_app/api';
import { API_ROUTES } from '../_app/routes';

export class PreviousLevelsStore {
	@observable previousLevels = [];
	@observable chosenPrevLevel = {};
	@observable chosenLevelMonthNumber = 0;

	@action async fetchPreviousLevels() {
		try {
			const response = await api.get(API_ROUTES.PREVIOUS_LEVELS);
			this.previousLevels = response.data.previousLevels.map((prevlevel) => {
				const prevLevelDate = new Date(prevlevel.deadline);
				const month = prevLevelDate.getMonth();
				const day = prevLevelDate.getDate();
				return {
					task: prevlevel.description,
					monthName: getMonthName(month),
					month: month,
					day: day,
					fullDateTime: prevLevelDate
				};
			});
			this.chosenPrevLevel = this.previousLevels[0];
			this.chosenLevelMonthNumber = this.chosenPrevLevel.month;
		} catch (e) {
			console.log(e);
		}
	}

	@action setPreviousLevel(levelMonth) {
		this.chosenPrevLevel = this.previousLevels.find(
			(prevLevel) => prevLevel.month === Number(levelMonth)
		);
		this.chosenLevelMonthNumber = Number(levelMonth);
	}

	mapMonthNumberToIndex(levelMonth) {
		// before previous levels are fetch, this function runs and return -1. That's why we need this if clause
		if (levelMonth === 0) return 0;
		return this.previousLevels.findIndex(
			(prevLevel) => prevLevel.month === Number(levelMonth)
		);
	}

	@computed get getPreviousLevelSectionTitle() {
		if (this.previousLevels.length === 0) {
			return '';
		}
		const today = new Date();
		const levelIndex = this.mapMonthNumberToIndex(this.chosenLevelMonthNumber);
		const monthOfChosenPreviousLevel = this.previousLevels[levelIndex]
			.fullDateTime;
		return today.getTime() >= monthOfChosenPreviousLevel.getTime()
			? 'Poprzedni Level'
			: 'Obecny level';
	}
}

export default new PreviousLevelsStore();
