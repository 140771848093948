import React from 'react';
import './styles/SectionNavigator.scss';
import ghostGreenImg from '../img/ghost_green.png';

function ghostOrDot(section, currentSection, index) {
	return currentSection === section ? (
		<img
			alt={ghostGreenImg}
			key={index}
			src={ghostGreenImg}
			className="section-navigator__img"
		></img>
	) : (
		<div key={index} className="section-navigator__dot" />
	);
}

const SectionNavigator = ({ sections, currentSection }) => {
	return (
		<div className="section-navigator">
			{sections.map((section, index) =>
				ghostOrDot(section, currentSection, index)
			)}
		</div>
	);
};

SectionNavigator.propTypes = {};

export default SectionNavigator;
