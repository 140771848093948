import axios from 'axios';

const axiosInstance = axios.create({
	// eslint-disable-next-line no-undef
	baseURL: process.env.REACT_APP_API_URL
});

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		console.log('ERROR !', error);
		return Promise.reject(error.response.data);
	}
);

export default axiosInstance;
