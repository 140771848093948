import { action, observable } from 'mobx';
import moment from 'moment';
import api from '../_app/api';
import { API_ROUTES } from '../_app/routes';

export class StatsStore {
	@observable user = null;
	@observable authError = '';
	@observable isLoggingOut = false;
	@observable isLoggingIn = false;
	@observable lastRefreshTokenTime = moment();
	@observable stats = [];
	@observable universitiesStats = [];

	@action async fetchStats() {
		try {
			const response = await api.get(API_ROUTES.STATS);
			this.stats = response.data;
		} catch (e) {
			console.log(e);
		}
	}

	@action async fetchUniversitiesStats() {
		try {
			const response = await api.get(API_ROUTES.GROUPS);
			this.universitiesStats = response.data;
		} catch (e) {
			console.log(e);
		}
	}
}

export default new StatsStore();
