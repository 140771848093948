import { Grid } from '@material-ui/core';
import React from 'react';
import './styles/GroupStatsSection.scss';
import { ColumnChart } from 'react-chartkick';
import 'chart.js';
import { useMediaQuery } from 'react-responsive';
import { inject, observer } from 'mobx-react';

const GroupStatsSection = ({ statsStore }) => {
	const isScreenBig = useMediaQuery({
		query: '(min-device-width: 900px)'
	});

	const data = statsStore.stats.map((e) => [
		`${e.shortName}       \n\n${e.name}`,
		e.points
	]);
	const colors = statsStore.stats.map((e) => e.color);
	const chartData = isScreenBig ? data : data.slice(0, 10);
	return (
		<div className="group-stats-section">
			<Grid container spacing={6} direction="row">
				<Grid
					container
					item
					justify="center"
					alignItems="center"
					md={12}
					xs={12}
				>
					<ColumnChart
						data={chartData}
						dataset={{
							backgroundColor: colors,
							borderColor: 'black'
						}}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

GroupStatsSection.propTypes = {};

export default inject('statsStore')(observer(GroupStatsSection));
