import { action, observable } from 'mobx';
import api from '../_app/api';
import { API_ROUTES } from '../_app/routes';

export class TasksStore {
	@observable completedMonths = [];
	@observable govsForMonth = [];
	@observable doneTasksForGov = [];
	@observable chosenMonthIndex = 0;
	@observable chosenGovIndex = '';

	@action async fetchMonths() {
		try {
			const completedMonths = await api.get(API_ROUTES.TASK_MONTHS);
			this.completedMonths = completedMonths.data;
		} catch (e) {
			console.log(e);
		}
	}
	@action async fetchGovsForMonth(month) {
		try {
			this.chosenMonth = month;
			const govsForMonth = await api.get(
				API_ROUTES.getGovsWithDoneTaskByMonth(month)
			);
			this.govsForMonth = govsForMonth.data;
		} catch (e) {
			console.log(e);
		}
	}

	@action async fetchDoneTasksForGovInMonth(gov) {
		try {
			this.chosenGov = gov;
			const doneTasksForGov = await api.get(
				API_ROUTES.getTasksForGovInMonth(this.chosenMonth, gov)
			); // []
			this.doneTasksForGov = doneTasksForGov.data.map(
				(file) => `${this.chosenMonth}/${this.chosenGov}/${file}`
			);
		} catch (e) {
			console.log(e);
		}
	}
}

export default new TasksStore();
