export const getMonthName = (monthNumber) => {
	const monthNames = [
		'Styczeń',
		'Luty',
		'Marzec',
		'Kwiecień',
		'Maj',
		'Czerwiec',
		'Lipiec',
		'Sierpień',
		'Wrzesień',
		'Październik',
		'Listopad',
		'Grudzień'
	];
	return monthNames[monthNumber];
};

export const monthNameMapper = {
	Styczeń: 'Stycznia',
	Luty: 'Lutego',
	Marzec: 'Marca',
	Kwiecień: 'Kwietnia',
	Maj: 'Maja',
	Czerwiec: 'Czerwca',
	Lipiec: 'Lipca',
	Sierpień: 'Sierpnia',
	Wrzesień: 'Września',
	Październik: 'Października',
	Listopad: 'Listopada',
	Grudzień: 'Grudnia'
};
