import React from 'react';
import './styles/SectionTitle.scss';

const SectionTitle = (props) => {
	const { title, id } = props;

	return (
		<div id={id} className="section-title">
			<div className="section-title__headline">{title}</div>
		</div>
	);
};

SectionTitle.propTypes = {};

export default SectionTitle;
