import React from 'react';
import './styles/StartSection.scss';

const StartSection = () => {
	return (
		<div className="start-section">
			<p className="start-section__info-wrapper">
				Każdy miesiąc studenckiej działalności jest niczym kolejny level
				wtajemniczenia w świat akademicki. Analogicznie każdy miesiąc naszej
				gry, to kolejny level wtajemniczenia przez zadania punktowane, które
				będą z jednej strony przypominać o ważnych elementach związanych z
				działalnością studencką, a z drugiej zachęcać do zabawy. Samorządy,
				które przez rok uzyskają ustaloną liczbę punktów przejdą całe wyzwanie z
				czym wiąże się wyróżnienie! Trzy najlepsze zespoły w Polsce uzyskają
				dostęp do specjalnego dodatku, zdobywając poziom Legendy Samorządności
				oraz nagrody!
			</p>
		</div>
	);
};

StartSection.propTypes = {};

export default StartSection;
