import { Grid } from '@material-ui/core';
import React from 'react';
import './styles/NextTaskSection.scss';
import 'chart.js';
import { inject, observer } from 'mobx-react';

const NextTaskSection = ({ configStore }) => {
	const getNumberOfDaysToNextTask = () => {
		const currentDate = Date.now();
		const oneDay = 24 * 60 * 60 * 1000;
		return Math.round(
			Math.abs((configStore.nextDeadline - currentDate) / oneDay)
		);
	};

	return (
		<div className="next-task-section">
			<Grid container direction="row" style={{ height: 300 }}>
				<Grid container item justify="center" alignItems="center">
					<div className="image-text-container">
						<h1 className="image-text-h">{getNumberOfDaysToNextTask()} DNI</h1>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

NextTaskSection.propTypes = {};

export default inject('configStore')(observer(NextTaskSection));
