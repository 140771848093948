import React, { Component } from 'react';
import {
	MapContainer,
	TileLayer,
	Polygon,
	CircleMarker,
	Popup
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { polandJson } from '../../_app/constants/poland';
import { isMobile } from 'react-device-detect';
import Grid from '@material-ui/core/Grid';

const purpleOptions = {
	fillColor: '#ffdf2b',
	color: 'black',
	fillOpacity: 0.2
};

class MapView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentLocation: { lat: 52.0, lng: 19.15 },
			zoom: 5,
			map: null
		};
	}

	render() {
		const circles = this.props.statsData;
		return (
			<MapContainer
				style={{ height: '30em', width: '100%' }}
				center={this.state.currentLocation}
				zoom={this.state.zoom}
				minZoom={5}
				scrollWheelZoom={true}
				zoomControl={true}
				dragging={!isMobile}
				touchZoom={true}
				doubleClickZoom={false}
			>
				<TileLayer
					attribution='&copy; <a href="http://psrp.org.pl">Podejmij wyzwanie z PSRP</a>'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					boundary={polandJson}
				/>
				<Polygon
					pathOptions={purpleOptions}
					positions={polandJson.features[0].geometry.coordinates}
				></Polygon>
				{circles.length > 0 &&
					circles.map((circle, index) => {
						return (
							<CircleMarker
								key={index}
								center={circle.geo.geometry.coordinates}
								pathOptions={{ color: circle.color ? circle.color : 'red' }}
								radius={circle.points / 7 + this.props.statsMultiplier}
							>
								<Popup>
									<Grid container>
										<Grid
											container
											item
											style={{
												width: 200,
												marginBottom: 10,
												fontSize: 17,
												color: '#ffdf2f'
											}}
											xs={12}
										>
											{circle.name}
										</Grid>
										<Grid container item xs={6}>
											<img
												style={{ height: 100, width: 100 }}
												alt={circle.logoUrl}
												src={circle.logoUrl}
											/>
										</Grid>
										<Grid container item xs={6}>
											<div
												style={{
													position: 'relative',
													height: 100,
													width: 100,
													fontSize: 40,
													color: '#ffdf2f'
												}}
											>
												<div
													style={{
														position: 'absolute',
														top: '50%',
														left: '55%',
														transform: 'translate(-50%, -50%)'
													}}
												>
													<sup>{circle.points}</sup>&frasl;<sub>70</sub>
												</div>
											</div>
										</Grid>
									</Grid>
								</Popup>
							</CircleMarker>
						);
					})}
			</MapContainer>
		);
	}
}

export default MapView;
