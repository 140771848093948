/* eslint-disable no-undef */
import React from 'react';
import './styles/TaskSection.scss';

// import { Swiper, SwiperSlide } from 'swiper/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// import api from '../../../_app/api';

import { inject, observer } from 'mobx-react';

const TaskSection = ({ tasksStore }) => {
	return (
		<div className="task-section">
			<div className="task-selector">
				{tasksStore.completedMonths.length !== 0 ? (
					<Autocomplete
						id="university"
						className="combo-selector"
						options={tasksStore.completedMonths}
						getOptionLabel={(option) => option}
						onChange={async (event, newValue) => {
							console.log(newValue);
							if (event.target.value !== undefined) {
								await tasksStore.fetchGovsForMonth(newValue);
							}
						}}
						renderInput={(params) => (
							<TextField {...params} label="Zadanie..." variant="outlined" />
						)}
					/>
				) : (
					<div />
				)}
				{tasksStore.govsForMonth.length !== 0 ? (
					<Autocomplete
						id="taskNumber"
						className="combo-selector"
						options={tasksStore.govsForMonth}
						getOptionLabel={(option) => option}
						onChange={async (event, newValue) => {
							console.log(newValue);
							if (newValue !== undefined) {
								await tasksStore.fetchDoneTasksForGovInMonth(newValue);
							}
						}}
						renderInput={(params) => (
							<TextField {...params} label="Uczelnia..." variant="outlined" />
						)}
					/>
				) : (
					<div />
				)}
			</div>
			{tasksStore.doneTasksForGov.length !== 0 ? (
				<div className="carousel-wrapper">
					<Carousel dynamicHeight={false}>
						{tasksStore.doneTasksForGov.map((taskUrl, index) => (
							<div className="task-slide" key={{ index }}>
								<img
									src={`${process.env.REACT_APP_API_URL}/${taskUrl}`.replace(
										'api/v1/',
										'images/'
									)}
								/>
							</div>
						))}
					</Carousel>
				</div>
			) : (
				<div />
			)}
		</div>
	);
};

TaskSection.propTypes = {};

export default inject('tasksStore')(observer(TaskSection));

// const TaskSection = () => {
// 	return (
// 		<div className="task-section">
// 			<Swiper
// 				spaceBetween={200}
// 				slidesPerView={1}
// 				onSlideChange={() => console.log('slide change')}
// 				onSwiper={(swiper) => console.log(swiper)}
// 			>
// 				<SwiperSlide
// 					style={{
// 						maxHeight: 400,
// 						maxWidth: 400,
// 						display: 'block',
// 						objectFit: 'contain'
// 					}}
// 				>
// 					<img src={image} />
// 				</SwiperSlide>
// 				<SwiperSlide
// 					style={{ maxHeight: 800, maxWidth: 400, objectFit: 'contain' }}
// 				>
// 					<img src={image} />
// 				</SwiperSlide>
// 				<SwiperSlide
// 					style={{ maxHeight: 800, maxWidth: 400, objectFit: 'contain' }}
// 				>
// 					<img src={image} />
// 				</SwiperSlide>
// 				<SwiperSlide
// 					style={{ maxHeight: 800, maxWidth: 400, objectFit: 'contain' }}
// 				>
// 					<img src={image} />
// 				</SwiperSlide>
// 			</Swiper>
// 		</div>
// 	);
// };

// TaskSection.propTypes = {};

// export default TaskSection;
