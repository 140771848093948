import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import './styles/index.scss';
import * as PropTypes from 'prop-types';
import StartSection from './sections/StartSection';
import SectionNavigator from 'components/SectionNavigator';
import ScrollTrigger from 'react-scroll-trigger';
import SectionTitle from './sections/SectionTitle';
import MapSection from './sections/MapSection';
import NextTaskSection from './sections/NextTaskSection';
import ContactSection from './sections/ContactSection';
import PreviousTaskSection from './sections/PreviousTaskSection';
import GroupStatsSection from './sections/GroupStatsSection';
import TaskSection from './sections/TaskSection';

const sections = [
	'startSection',
	'mapSection',
	'GroupStatsSection',
	'taskSection',
	'nextTaskSection',
	'previousTaskSection',
	'contactSection'
];

const MainPageContainer = ({
	statsStore,
	configStore,
	previousLevelsStore,
	tasksStore
}) => {
	useEffect(() => {
		statsStore.fetchStats();
		statsStore.fetchUniversitiesStats();
		configStore.fetchConfig();
		previousLevelsStore.fetchPreviousLevels();
		tasksStore.fetchMonths();
	}, []);
	const [currentSection, setCurrentSection] = useState(sections[0]);
	return (
		<div className="main-page-container">
			<SectionNavigator sections={sections} currentSection={currentSection} />
			<ScrollTrigger onEnter={() => setCurrentSection(sections[0])}>
				<StartSection id="start-section" />
			</ScrollTrigger>
			<SectionTitle id={'map-section-title'} title={'WYNIKI BRANŻÓWEK'} />
			<ScrollTrigger onEnter={() => setCurrentSection(sections[1])}>
				<MapSection />
			</ScrollTrigger>
			<SectionTitle title={'WYNIKI UCZELNI'} />
			<ScrollTrigger onEnter={() => setCurrentSection(sections[2])}>
				<GroupStatsSection data={statsStore.universitiesStats} />
			</ScrollTrigger>
			<SectionTitle
				id={'next-task-section-title'}
				title={'KOLEJNE ZADANIE ZA'}
			/>
			<ScrollTrigger onEnter={() => setCurrentSection(sections[4])}>
				<NextTaskSection />
			</ScrollTrigger>
			<SectionTitle
				id={'previous-task-title'}
				title={previousLevelsStore.getPreviousLevelSectionTitle.toUpperCase()}
			/>
			<ScrollTrigger onEnter={() => setCurrentSection(sections[5])}>
				<PreviousTaskSection />
			</ScrollTrigger>
			<SectionTitle title={'WASZE ZADANIA'} />
			<ScrollTrigger onEnter={() => setCurrentSection(sections[3])}>
				<TaskSection />
			</ScrollTrigger>
			<SectionTitle id={'contact-section-title'} title={'KONTAKT'} />
			<ScrollTrigger onEnter={() => setCurrentSection(sections[6])}>
				<ContactSection />
			</ScrollTrigger>
		</div>
	);
};

MainPageContainer.propTypes = {
	statsStore: PropTypes.shape({
		fetchStats: PropTypes.func,
		fetchUniversitiesStats: PropTypes.func,
		universitiesStats: PropTypes.array
	}),
	configStore: PropTypes.shape({
		fetchConfig: PropTypes.func
	}),
	previousLevelsStore: PropTypes.shape({
		fetchPreviousLevels: PropTypes.func
	}),
	tasksStore: PropTypes.shape({
		fetchGovsForMonth: PropTypes.func,
		fetchDoneTasksForGovInMonth: PropTypes.func
	})
};

export default inject(
	'statsStore',
	'configStore',
	'previousLevelsStore',
	'tasksStore'
)(observer(MainPageContainer));
