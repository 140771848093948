import { FormControl, Grid, NativeSelect } from '@material-ui/core';
import React from 'react';
import './styles/PreviousTaskSection.scss';
import 'chart.js';
import { inject, observer } from 'mobx-react';
import { monthNameMapper } from '../../../utils/formatDateHelper';
import { Scrollbars } from 'react-custom-scrollbars';

const PreviousTaskSection = ({ previousLevelsStore }) => {
	const getPreviousLevelsOptions = () => {
		return previousLevelsStore.previousLevels.map((prevLevel) => (
			<option key={prevLevel.month} value={prevLevel.month}>
				{prevLevel.monthName}
			</option>
		));
	};

	const handleLevelChange = (chosenLevelMonth) => {
		previousLevelsStore.setPreviousLevel(chosenLevelMonth);
	};

	return (
		<div className="previous-task-section">
			<Grid container direction="row">
				<Grid container item justify="space-evenly">
					<div className="date-info-container">
						<div className="image-text-container">
							<h1 className="image-text-h">
								{previousLevelsStore.chosenPrevLevel.day}
							</h1>
						</div>
						<label style={{ color: '#00006C' }} className="vertical-text">
							{previousLevelsStore.chosenPrevLevel.monthName
								? monthNameMapper[
										previousLevelsStore.chosenPrevLevel.monthName
								  ].toUpperCase()
								: ''}
						</label>
					</div>
					<div className="task-description">
						<Scrollbars>{previousLevelsStore.chosenPrevLevel.task}</Scrollbars>
					</div>
				</Grid>
			</Grid>
			<div className="date-picker-container">
				<FormControl className="date-picker-input">
					<NativeSelect
						value={previousLevelsStore.chosenPrevLevel.month}
						onChange={(e) => handleLevelChange(e.target.value)}
					>
						{getPreviousLevelsOptions()}
					</NativeSelect>
				</FormControl>
			</div>
		</div>
	);
};

PreviousTaskSection.propTypes = {};

export default inject('previousLevelsStore')(observer(PreviousTaskSection));
